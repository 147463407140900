.card {
    position: relative;
}

.char-image {
    width: 70%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.char-image img {
    max-width: 330px;
    transition: 0.3s;
    z-index: 1;
}

.arrow {
    position: absolute;
    left: 0px;
    bottom: 0px;
    background-color: transparent;
    color: black;
    font-size: 30px;
    width: 25%;
    min-width: 40px;
    height: 25%;
    min-height: 40px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (min-width: 1024px) {
    .arrow {
        bottom: -3px;
    }

    .card:first-child .arrow {
        bottom: -7px;
    }
}

@media screen and (min-width: 1400px) {
    .card:last-child .arrow {
        bottom: -21px;
    }
}

@media screen and (min-width: 1535px) {
    .card .arrow {
        bottom: -6px;
    }

    .card:first-child .arrow {
        bottom: -12px;
    }

    .card:last-child .arrow {
        bottom: -28px;
    }
}

@media screen and (min-width: 1600px) {
    .card .arrow {
        bottom: -1px;
    }

    .card:last-child .arrow {
        bottom: -21px;
    }
    .card:first-child .arrow {
        bottom: -8px;
        left: -3px;
    }
}

.card:hover img:not(.arrow) {
    transform: scale(1.07);
    transform-origin: bottom center;
}
