.ul {
    list-style: none;
    background-color: #F0F0F0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 35px;
    gap: 10px;
    margin-inline-start: auto;
    border: 1px white solid;
    padding: 2px;
    position: relative;
    z-index: 3;

}

.ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 500;
    transition: 0.3s;
    width: fit-content;
    padding: 8px 15px;
    border-radius: 25px;
}

.ul li a:hover,
.ul li a.active {
    background-color: white !important;
}

.mainMenu {
    position: relative;
}

.ulBg {
    position: absolute;
    top: 0;
    right: -1px;
    width: 100%;
    transform: scale(1.1);
    transform-origin: right top;
    z-index: 1;
}

[dir=rtl] .ulBg {
    right: unset;
    left: calc(-100% - 1px);
    transform: scale(1.1) rotateY(180deg);
}
