.footer {
    background-color: #F5F5F5;
}

.container {
    display: flex;
    gap: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    flex-wrap: wrap;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 200px;
    color: #525252;

}

@media (max-width:991px) {
    .logo {
        width: 100% !important;
    }
}


.logo img {
    display: block;
    margin-bottom: 30px;
    width: 80px;
    height: 80px;
}

.links {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;
    align-items: flex-start;
}


@media (max-width:991px) {
    .links {
        flex-direction: column;
        align-items: start;
    }
}

.col {
    height: fit-content;
}

.col h3 {
    font-weight: 600;
    margin-bottom: 10px;
}

.col li {
    margin-bottom: 10px;
}

.col li a {
    font-size: 16px;
    color: #525252;
}

.col li a:hover {
    color: #E6932B;
}

.copyRight {
    padding: 20px 20px 50px;
    color: #525252;
    font-size: 16px;
    border-top: 1px solid #909090;
}
