.send-message-btn {
    @apply bg-white text-black transition-all gap-3;
}

.send-message-btn svg {
    @apply transition-all absolute right-0 opacity-0;
    width: 1px;
}

.send-message-btn:hover svg {
    @apply relative opacity-100;
    width: 20px;
}
