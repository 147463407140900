.info-title::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 9%;
    height: 2px;
    border-radius: 1px;
    background-color: white;
}
