main {
    columns: 3;
    gap: 20px;
}

main figure {
    margin-bottom: 1rem;
    break-inside: avoid;
    border-radius: 3vw;
    overflow: hidden;
    width: 100%;
}

main figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

main figure:nth-child(6n + 1),
main figure:nth-child(6n + 4) {
    aspect-ratio: 1/1.45;
}

main figure:nth-child(6n + 2),
main figure:nth-child(6n + 3) {
    aspect-ratio: 1;
}

main figure:nth-child(6n + 5),
main figure:nth-child(6n + 6) {
    aspect-ratio: 1/1.22;
}

:root {
    --lightbox-toolbar-top: 8%; /* Default for small and medium screens */
}

@media (min-width: 1024px) {
    /* Large screens and up */
    :root {
        --lightbox-toolbar-top: 0px;
    }
}

/* Tablet View */
@media screen and (max-width: 768px) {
    main {
        columns: 2;
        gap: 15px;
    }
}

/* Mobile View */
@media screen and (max-width: 480px) {
    main {
        columns: 1;
        gap: 10px;
    }

    main figure {
        border-radius: 5vw;
    }
}
