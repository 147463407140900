.mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50000;
    background-color: white;
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500000;
}
