.send-message {
    @apply bg-white w-fit h-[60px] px-5 rounded-[35px] border border-gray-900 hover:border-white text-neutral-900 text-[22px] font-semibold gap-5 transition-all flex items-center justify-center;
}

.send-message svg {
    @apply transition-all absolute right-0 opacity-0;
    width: 1px;
}

.send-message:hover svg {
    @apply relative opacity-100;
    width: 20px;
}
