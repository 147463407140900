.services {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
}

@media screen and (min-width: 1280px) {
    .services {
        grid-template-columns: repeat(3, minmax(300px, 1fr));
    }
}

@media (max-width: 768px) {
    .services {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
}
