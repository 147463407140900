.carousel {
    display: flex;
    overflow: hidden;
    cursor: grab;
}

.item {
    height: 30vw;
    width: 22vw;
    max-width: 380px;
    max-height: 520px;
    min-width: 185px;
    min-height: 250px;
    overflow: hidden;
    border-radius: 31px;
    cursor: grab;
    background-size: cover;
    background-repeat: no-repeat;
}

:root {
    --lightbox-toolbar-top: 8%; /* Default for small and medium screens */
}

@media (min-width: 1024px) {
    /* Large screens and up */
    :root {
        --lightbox-toolbar-top: 0px;
    }
}

@media (min-width: 1400px) {
    .item {
        width: 26vw;
        height: 36vw;
    }
}

.item img {
    max-width: 100%;
    height: 100%;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #171717;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 10;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftArrow {
    left: 10px;
}

.rightArrow {
    right: 10px;
}

.shape {
    position: absolute;
    top: 0;
    left: 207px;
    width: 400px;
    height: 700px;
    background-color: #ff930f3d;
    border-radius: 250px;
    transform: rotate(62deg);
}
