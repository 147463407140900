.sectionImage {
    width: 57%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50px;
}

@media (max-width: 1024px) {
    .sectionImage {
        width: 100%;
        height: 280px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0 !important;
    }
}

.services-list {
    color: white;
    padding-inline-start: 15px;
    padding-bottom: 90px;
}

.services-list h2 {
    font-size: 50px;
    font-weight: semibold;
    margin-bottom: 20px;
}

.services-list ul li a {
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    text-transform: capitalize;
    border-bottom: 1px solid #FAFAFA30;
    font-size: 30px;
    transition: 0.3s;
    min-width: 400px;
}

.services-list ul li a:hover {
    padding-inline-start: 10px;
    color: #E6932B;
}