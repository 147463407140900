.see-our-projects-btn {
    @apply bg-gray-900 text-white gap-3 transition-all;
}

.see-our-projects-btn svg {
    @apply transition-all absolute right-0 opacity-0;
    width: 1px;
}

.see-our-projects-btn:hover svg {
    @apply relative opacity-100;
    width: 20px;
}
