@tailwind base;
@tailwind components;
@tailwind utilities;

/* English Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
/* Arabic Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

html[dir="ltr"] body {
    font-family: "Poppins", sans-serif;
}

html[dir="ltr"] body .ff-inter {
    font-family: "Inter", sans-serif;
}

html[dir="ltr"] body .ff-manrope {
    font-family: "Manrope", sans-serif;
}

html[dir="rtl"] body {
    font-family: "Cairo", sans-serif;
}

body {
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

#root {
    overflow: hidden;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.header ul li a.active {
    background-color: white !important;
}

@media screen and (min-width: 1024px) {
    .video+span {
        
    }
}

.active-cat {
    border: 2px solid #0A0A0A;
    color: #0A0A0A;
}

.overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
}
