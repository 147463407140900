.section {
    background-size: 60% 100%;
}

@media screen and (max-width: 1024px) {
    .section {
        background: #F5F5F5;
        background-image: none;
        margin-bottom: 40px;
    }
}

.images div {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.24);
}

@media (max-width: 1024px) {
    .images div {
        transform: scale(0.9);
    }

    .images div:nth-child(2) {
        transform: scale(1.3);
        z-index: 1;
    }
}
