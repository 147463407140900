.section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.container {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* padding: 20px; */
    flex-wrap: wrap;
    gap: 1px;
    /* max-width: 1200px; */
}

.info {
    width: 35%;
}

.info p {
    font-weight: 400;
    color: #737373;
    line-height: 1.6;
    margin-bottom: 50px;
    /* font-size: 40px; */
}

.image {
    width: 59%;
}

@media (min-width: 768px) {
    .image {
        width: 50%;
    }
}


.container .image img {
    max-width: 100%;
}


.links,
.links2 {
    display: flex;
    width: 100%;
    justify-content: start;
    gap: 20px;
}

@media (min-width: 1024px) {
    .links {
        display: none;
    }
}

@media (max-width: 1024px) {
    .info p {
        margin-top: 10%;
        font-size: 14px;
    }

    .links2 {
        display: none;
    }
}

.links a,
.links2 a {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1px #737373 solid;
    font-size: 15px;
    padding: 12px 15px;
    transition: 0.5s;
    text-wrap-mode: nowrap;
}

@media (max-width:791px) {

    .links a,
    .links2 a {
        font-size: 13px;
    }

}

.links a svg,
.links2 a svg {
    width: 1px;
    transition: 0.5s;
    opacity: 0;
}

.links a:hover svg,
.links2 a:hover svg {
    width: 30px;
    opacity: 1;
}
