.open {
    transition: 0.3s;
    transform: scale(1);
    left: 0;
}

.close {
    height: 0;
    transform: scale(0);
}

html[dir="rtl"] .langSw {
    right: 0;
    transform-origin: top right;
}

html[dir="ltr"] .langSw {
    left: 0;
    transform-origin: top left;
}

/* .video+span {
  transform: scaleX(0);
}

.video:hover+span {
  transform: scaleX(1);
} */

.headerShap::before {
    content: "";
    position: absolute;
    right: -54px;
    top: 0;
    width: 60px;
    height: 55px;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent transparent white;
    border-radius: 50%;
    transform: rotate(48deg);
}
