.open {
    height: fit-content;
    transition: 0.3s;
}

.close {
    height: 0;
}

@media screen and (max-width: 1024px) {
    .button {
        color: white;
        border: 1px solid white;
        border-radius: 20px;
        padding: 8px 30px;
    }
}
