.carousel {
    cursor: grab;
    position: relative;
}

.inner-carousel {
    display: flex;
    gap: 20px;
    align-items: end;
}

@media screen and (max-width: 1024px) {
    .inner-carousel {
        gap: 9px;
    }
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    z-index: 2;
}

.leftArrow {
    left: 10px;
    /* Position on the left */
}

.rightArrow {
    right: 10px;
    /* Position on the right */
}
